let carouselClass = 'j-carousel';

var initializeSlider = function($block, $attributes) {
	let elem = $block;
	if ( !$block.hasClass(carouselClass) ) {
		elem = $block.find("." + carouselClass);
	}

	elem.each(function() {
		let fade = $(this).data('fade');
		let dots = $(this).data('dots');
		let cent = $(this).data('center');
		$(this).slick({
			'autoplay' : false,
			'centerMode': cent,
			'slide' : '.b-carousel--slide',
			'slidesToShow' : 1,
			'slidesToScroll' : 1,
			'rows' : 0,
			'fade' : fade,
			'infinite' : true,
			'dots' : dots,
			'arrows' : true,
			'prevArrow' : '<span class="b-carousel--arrow b-carousel--prev"><svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 0.99974L1 11.295L13 21.5903" stroke="black" stroke-linecap="round" stroke-linejoin="round"/></svg></span>',
			'nextArrow' : '<span class="b-carousel--arrow b-carousel--next"><svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 21.5906L13 11.2953L1 1" stroke="black" stroke-linecap="round" stroke-linejoin="round"/></svg></span>'
		});
	});
};

// Initialize each block on page load (front end).
$(document).ready(function($) {
	$("." + carouselClass).each(function() {
		initializeSlider($(this), false);
	});
});